import { Link } from '@mui/material';
import { Typography, TypographyTypeMap } from '@mui/material';

import { GET_CREATOR_PATH } from '../../constants/routings';

export default function UsernameTitle({
  username,
  overlay = false,
  variant = 'body1',
  ...props
}: {
  username: string;
  overlay?: boolean;
  variant?: string;
} & TypographyTypeMap['props']) {
  // TODO: Add a link to the user's profile

  return (
    <Link
      href={GET_CREATOR_PATH(username)}
      color="text.primary"
      sx={{
        display: 'flex',
        ...(overlay && {
          position: 'initial',
          '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          },
        }),
      }}
    >
      <Typography
        variant={variant}
        noWrap
        sx={{
          maxWidth: { xs: '102px', sm: 'none' },
        }}
        {...props}
      >
        {username}
      </Typography>
    </Link>
  );
}
