import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import useSupabase from '../supabase';
import { LeaderboardResponse } from '../types/getLeaderboard';

export function useGetLeaderboard({
  enabled = true,
}: { enabled?: boolean } = {}) {
  const client = useSupabase();

  const queryKey = ['get_leaderboard'];

  const queryFn = async (): Promise<LeaderboardResponse> => {
    const { data, error } = await client.rpc('get_leaderboard');

    if (error) {
      throw new Error(error.message);
    }

    if (!data) {
      throw new Error('No data returned from the server');
    }

    return data as LeaderboardResponse;
  };

  return useQuery<LeaderboardResponse, Error>({
    queryKey,
    queryFn,
    enabled,
    keepPreviousData: true,
  } as UseQueryOptions<LeaderboardResponse, Error>);
}
