import { Box, MenuItem, MenuList, Typography } from '@mui/material';
import { ButtonMenu } from './ButtonMenu';
import { CheckIcon, ChevronDown } from '../icons';

export enum SortByOptions {
  TOP_TRENCHES = 'Top Trenches',
  TRENDING_TRENCHES = 'Trending Trenches',
}

interface SortByProps {
  onSortChange: (option: SortByOptions) => void;
  sortOptionSelected: SortByOptions;
  hasFilter?: boolean;
}
interface SortMenuItemProps {
  text: string;
  isSelected: boolean;
  onClick: () => void;
}

const SortMenuItem: React.FC<SortMenuItemProps> = ({
  text,
  isSelected,
  onClick,
}) => {
  return (
    <MenuItem onClick={onClick}>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      >
        <Typography>{text}</Typography>
        {isSelected && <CheckIcon />}
      </Box>
    </MenuItem>
  );
};

export function SortBy({
  onSortChange,
  sortOptionSelected,
  hasFilter,
}: SortByProps) {
  const { TOP_TRENCHES, TRENDING_TRENCHES } = SortByOptions;

  const customButton = (
    <Box
      sx={{
        display: 'flex',
        gap: hasFilter ? '2px' : 2,
        px: 4,
        py: 3,
        borderRadius: 3,
        border: '1px solid #262829',
        backgroundColor: '#111314',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Typography
          sx={{ fontSize: '16px', fontWeight: '300', lineHeight: '20px' }}
        >
          Sort by
        </Typography>
        {hasFilter && (
          <Box
            sx={{
              height: '6px',
              width: '6px',
              backgroundColor: '#38FF93',
              borderRadius: '100%',
            }}
          />
        )}
      </Box>
      <ChevronDown />
    </Box>
  );
  return (
    <ButtonMenu customButton={customButton} removePadding={true}>
      <MenuList sx={{ width: '220px' }}>
        <SortMenuItem
          text={TOP_TRENCHES}
          isSelected={sortOptionSelected === TOP_TRENCHES}
          onClick={() => onSortChange(TOP_TRENCHES)}
        />
        <SortMenuItem
          text={TRENDING_TRENCHES}
          isSelected={sortOptionSelected === TRENDING_TRENCHES}
          onClick={() => onSortChange(TRENDING_TRENCHES)}
        />
      </MenuList>
    </ButtonMenu>
  );
}
