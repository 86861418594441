import { Address } from 'viem';
import { useReadContracts } from 'wagmi';

import SubscribeRegistryAbi from '../../../assets/abi/SubscribeRegistry';
import { SubscribeRegistryPackages } from '../../../types/web3/SubscribeRegistryPackages';
import { OnchainResult } from '../../types';

export function useGetSubPricesByAddressesBatch(creatorsAddresses: Address[]) {
  const notEmptyAddresses = creatorsAddresses.filter((addr) => !!addr);

  const contracts: any[] = notEmptyAddresses.map((creatorAddress) => ({
    address: import.meta.env.VITE_SUBSCRIBE_REGISTRY_ADDRESS as Address,
    abi: SubscribeRegistryAbi,
    functionName: 'getSubPrice',
    args: [creatorAddress, SubscribeRegistryPackages.MONTHLY],
  }));

  const contractReadResult = useReadContracts({
    contracts,
  });

  const subPricesByAddressMap = notEmptyAddresses.reduce(
    (map, address, index) => {
      const res = contractReadResult?.data?.[index];
      if (res && res.status === 'success') {
        map[address] = { result: res.result as bigint, status: res.status };
      } else {
        map[address] = { result: null, status: 'failure' };
      }
      return map;
    },
    {} as Record<Address, OnchainResult<bigint>>,
  );

  return { ...contractReadResult, data: subPricesByAddressMap };
}
