import { Box, Grid, Skeleton, Typography } from '@mui/material';

import { Trench } from '../../../../api/types/getLeaderboard';
import { GET_CREATOR_PATH } from '../../../../constants/routings';
import { useMainTokenInfo } from '../../../../hooks/useMainTokenInfo';
import formatBigintWithDecimals from '../../../../lib/formatBigintWithDecimals';
import navigate from '../../../../lib/navigate';
import { getVideoEmbedUrl, isVideoUrl } from '../../../../lib/mediaValidations';
import { OnchainResult } from '../../../../web3/types';
import {
  CommunityMembersSmall,
  CreatorRevenueSmall,
  SharedRevenueSmall,
} from '../../../icons';
import AvatarWithBorder from '../../AvatarWithBorder';

import { PositionBadge } from './PositionBadge';
import { StatsItem } from './StatsItem';
import { ByCreator } from '../../ByCreator';
import { ASPECT_RATIO_16_9 } from '../../../../constants/imageAspectRatio';

type ICreatorData = Trench;

interface ICreatorLeaderboardCardProps {
  creator: ICreatorData;
  subPriceResult: OnchainResult<bigint | null>;
  subPriceLoading: boolean;
}
const MAX_FRACTION_DIGITS_FOR_PRICE = 4;

export function CreatorLeaderboardCard({
  creator,
  subPriceResult,
  subPriceLoading,
}: ICreatorLeaderboardCardProps) {
  const { decimals, symbol } = useMainTokenInfo();

  const {
    avatar,
    media,
    username,
    bio,
    rank,
    subs,
    creator_revenue,
    shared_revenue,
    community_name,
  } = creator;

  const handleCreatorNavigation = () => {
    navigate(GET_CREATOR_PATH(username));
  };

  // get price dynamic
  const hasMedia = media && media.length > 0;
  const mediaUrl = media?.[0] ?? '';

  const costInEth = formatBigintWithDecimals(
    subPriceResult?.result,
    decimals,
    MAX_FRACTION_DIGITS_FOR_PRICE,
  );

  const subPriceTextColor =
    !subPriceResult || subPriceResult.status === 'failure'
      ? 'error'
      : 'text.primary';

  const mediaSrc = getVideoEmbedUrl(mediaUrl) ?? mediaUrl;

  return (
    <Grid item xs={12} md={4}>
      <Box
        sx={{
          border: '1px solid #262829',
          borderRadius: '16px',
          backgroundColor: '#111314',
          transition: 'transform 0.3s ease-in-out',
          height: '100%',
          minHeight: { xs: 'auto', md: '370px' },
          '&:hover': {
            border: '1px solid #383B3D',
            cursor: 'pointer',
          },
        }}
        onClick={handleCreatorNavigation}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            pt: {
              xs: hasMedia ? ASPECT_RATIO_16_9 : '70px',
              md: ASPECT_RATIO_16_9,
            },
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'center',
            backgroundColor: 'transparent',
          }}
        >
          {hasMedia ? (
            <Box
              component={isVideoUrl(mediaUrl ?? '') ? 'iframe' : 'img'}
              src={mediaSrc}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none',
                objectFit: 'cover',
                pointerEvents: 'none',
              }}
            />
          ) : (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 20,
              }}
            >
              <AvatarWithBorder name={username} src={avatar} size={40} />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            py: 4,
            px: 5,
            gap: 6,
            justifyContent: 'space-between',
            height: { xs: 'auto', md: '190px' },
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontWeight: '400',
                      lineHeight: '25px',
                      color: '#F0F5FA',
                    }}
                  >
                    {community_name ?? username}
                  </Typography>
                  <PositionBadge rank={rank} />
                </Box>
                {community_name && <ByCreator username={username} isSmall />}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  textAlign: 'right',
                }}
              >
                {subPriceLoading && (
                  <Skeleton animation={false} width={90} height={15} />
                )}

                {!subPriceLoading && (
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '20px',
                      minWidth: '74px',
                      width: 'fit-content',
                    }}
                    color={subPriceTextColor}
                  >
                    {costInEth} {symbol}
                  </Typography>
                )}

                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 300,
                    color: '#9B9FA3',
                    lineHeight: '17px',
                  }}
                >
                  /month
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 300,
                color: '#9B9FA3',
                lineHeight: '17px',
                pt: 3,
              }}
            >
              {bio}
            </Typography>
          </Box>

          <Grid container columnSpacing={4}>
            <StatsItem
              icon={<CommunityMembersSmall />}
              title="Members"
              value={subs}
            />
            <StatsItem
              icon={<CreatorRevenueSmall />}
              title="Revenue"
              value={creator_revenue}
              isDollar
            />
            <StatsItem
              icon={<SharedRevenueSmall />}
              title="Shared"
              value={shared_revenue}
              isDollar
            />
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}
