import { useEffect, useRef } from 'react';

import { REF_MODAL_SHOWN_KEY } from '../../../components/shared/Modals/ReferFriendsModal';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { ProfileSetupProgressProvider } from '../../../providers/ProfileSetupProgressProvider';

import { LeaderboardPageContent } from './LeaderboardPageContent';

export function Page() {
  const { showModal } = useGlobalModal();
  const modalShown = useRef<boolean>(false);

  useEffect(() => {
    const value = localStorage.getItem(REF_MODAL_SHOWN_KEY);
    if (value?.toLowerCase() === 'true' || modalShown.current) {
      return;
    }

    showModal({ modalType: 'ReferFriendsModal' });

    modalShown.current = true;
  }, []);

  return (
    <ProfileSetupProgressProvider>
      <LeaderboardPageContent />
    </ProfileSetupProgressProvider>
  );
}
